import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobileOrTablet } from 'utils/Utils';
import {
  Header,
  Logo,
  Nav,
  SettingsAction,
  Title,
  MainLink,
  DropdownMenuContainer,
} from './styles';
import { MainSettingsMenu } from 'components/Settings';
import { GoThreeBars } from 'react-icons/go';
import { appActions } from 'store/app/actions';

class NavBar extends Component {
  constructor() {
    super();

    this.showSettingsMenu = this.showSettingsMenu.bind(this);
    this.hideSettingsMenu = this.hideSettingsMenu.bind(this);
    this.toggleSettingsMenu = this.toggleSettingsMenu.bind(this);
  }

  hideSettingsMenu() {
    this.props.setSettingsMenuVisibility(false);
  }

  showSettingsMenu() {
    this.props.setSettingsMenuVisibility(true);
  }

  toggleSettingsMenu(evt) {
    evt.preventDefault();
    const { mainMenuVisible } = this.props;
    this.props.setSettingsMenuVisibility(!mainMenuVisible);
  }

  render() {
    // to avoid conflict between onMouseEnter/onClick events in mobile devices, only set one binding per device type
    const isMobile = isMobileOrTablet();
    return (
      <Header>
        <MainLink href='/'>
          <Logo viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
            <path d='M20.496 9.026c-2.183-.93-5.116-1.53-8.25-1.695-.5-.03-.987-.04-1.45-.04 2.318-2.83 4.802-4.73 6.437-4.79.322-.013.595.055.813.196.706.458.905 1.768.545 3.59-.04.25.12.493.36.54.25.05.49-.11.54-.36.45-2.28.12-3.846-.94-4.538-.38-.248-.84-.365-1.35-.346-2.05.077-4.94 2.3-7.59 5.72-1.154.035-2.24.13-3.232.287-.646-2.897-.39-4.977.594-5.477.138-.073.285-.11.457-.124.697-.054 1.66.395 2.71 1.27.194.16.486.14.646-.06a.458.458 0 00-.06-.645C9.466 1.51 8.304 1 7.354 1.07a2.244 2.244 0 00-.803.22c-1.19.607-1.67 2.327-1.37 4.838.07.52.16 1.062.29 1.62C2.19 8.404.1 9.718.01 11.372c-.06 1.17.865 2.284 2.68 3.222a.46.46 0 10.42-.816C1.66 13.032.88 12.19.927 11.42c.05-1.08 1.772-2.19 4.76-2.78.27.994.62 2.032 1.05 3.09-1.018 1.888-1.756 3.747-2.137 5.4-.56 2.465-.26 4.22.86 4.948.36.234.78.35 1.247.35.935 0 2.067-.46 3.347-1.372a.458.458 0 10-.53-.746c-1.544 1.103-2.844 1.472-3.562 1.003-.76-.495-.926-1.943-.46-3.976.32-1.386.907-2.93 1.708-4.52.2.438.41.876.63 1.313 1.425 2.796 3.17 5.227 4.91 6.845 1.386 1.29 2.674 1.963 3.735 1.963.35 0 .68-.075.976-.223 1.145-.585 1.64-2.21 1.398-4.575-.224-2.213-1.06-4.91-2.354-7.6a.46.46 0 00-.83.396c2.69 5.602 2.88 10.19 1.37 10.96-1.59.813-5.424-2.355-8.39-8.18-.34-.655-.637-1.3-.9-1.93.34-.608.7-1.22 1.095-1.83.395-.604.806-1.188 1.224-1.745h.394c.54 0 1.126.01 1.734.048 6.53.343 10.975 2.56 10.884 4.334-.04.765-.924 1.538-2.425 2.12a.464.464 0 00-.26.596.455.455 0 00.593.262c1.905-.74 2.95-1.756 3.01-2.93.07-1.33-1.17-2.61-3.5-3.6v-.01zM8.08 9.45c-.27.415-.52.827-.764 1.244a23.66 23.66 0 01-.723-2.215c.713-.11 1.485-.19 2.31-.24-.28.39-.554.794-.82 1.21v-.01zm3.925 1.175a1.375 1.375 0 100 2.75 1.375 1.375 0 100-2.75z'></path>
          </Logo>
          <Title>HKS Reader</Title>
        </MainLink>
        <Nav>
          <DropdownMenuContainer
            onMouseEnter={!isMobile ? this.showSettingsMenu : null}
            onMouseLeave={!isMobile ? this.hideSettingsMenu : null}
          >
            <SettingsAction>
              <GoThreeBars onClick={isMobile ? this.toggleSettingsMenu : null} />
            </SettingsAction>
            <MainSettingsMenu />
          </DropdownMenuContainer>
        </Nav>
      </Header>
    );
  }
}

const mapStateToProps = state => {
  return {
    theme: state.app.theme,
    mainMenuVisible: state.app.mainMenuVisible,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSettingsMenuVisibility: visible =>
      dispatch(appActions.setSettingsMenuVisibility({ visible: visible })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
